<template>
    <div class="base-container">
        <el-card class="box-card">
            <div class="item" v-loading="loading">
                <el-carousel v-if="dataList.length>0" ref="carouselRef" :interval="5000" :initial-index="initialIndex"
                             :autoplay="false"
                             :loop="false" arrow="never"
                             indicator-position="none">
                    <el-carousel-item v-for="(item, index) in dataList" :key="index">
                        <div class="giveMark flex">
                            <!-- left -->
                            <div class="mark_left">
                                <i class="arrow el-icon-arrow-left" @click="onPrev()"></i>
                                <i class="arrow el-icon-arrow-right" @click="onNext()"></i>
                                <div class="imgBox img_big">
                                    <el-image
                                            fit="contain"
                                            :src="$store.getters.getFilePath+item.images"
                                            :preview-src-list="[$store.getters.getFilePath+item.images]"
                                    >
                                    </el-image>
                                </div>
                                <div class="work-content">
                                    <div>作品编号：{{item.number}}</div>
                                    <!--<div>精度：{{item.precision}}</div>-->
                                </div>
                            </div>
                            <!-- right -->
                            <div class="mark_right">
                                <!-- 科目名称 -->
                                <div class="generalReview aa">
                                    <div class="flex">
                                        <div class="item-box">科目名称: {{$store.state.subjectObj.subject_name}}</div>
                                        <div class="item-box">作品总数: {{$store.state.score_all_sum}}</div>
                                    </div>
                                    <div class="flex" v-if="$store.state.examInfo.exam_type != 2">
                                        <div class="item-box">已经评阅（{{$store.state.score_sum}}）:</div>
                                        <div class="item-box item-box-r">
                                            <el-progress :percentage="$store.state.score_sum_cost"></el-progress>
                                        </div>
                                    </div>
                                </div>

                                <div style="text-align: center;margin-top: 40px">
                                    <el-button type="primary" @click="setNormal(item.id_card, 2)">取消异常</el-button>
                                </div>
                            </div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
                <el-empty v-else class="empty-area" description="暂无数据"></el-empty>
            </div>
        </el-card>
    </div>
</template>

<script>
    import {getAbnormalList, setYjAbnormal} from "../../api/abnormal";

    export default {
        name: "abnormal",
        data() {
            return {
                query: {
                    start: 1,
                    size: 1,
                },
                total: 0,
                dataList: [],
                initialIndex: 0,
                loading: false,
                isDisKeyDown: false,// true-禁用keyDown
            };
        },
        created() {
            this.getList();
            this.keyDown();
        },
        methods: {
            // 监听键盘-左右键翻页
            keyDown() {
                document.onkeydown = (e) => {
                    //事件对象兼容
                    let e1 = e || event || window.event || arguments.callee.caller.arguments[0];
                    //键盘按键判断:左箭头-37;上箭头-38；右箭头-39;下箭头-40
                    //左
                    if (e1 && e1.keyCode == 37 && !this.isDisKeyDown) {
                        // 按下左箭头
                        this.onPrev();
                    } else if (e1 && e1.keyCode == 39 && !this.isDisKeyDown) {
                        // 按下右箭头
                        this.onNext();
                    }
                }
            },
            onPrev() {
                if (this.query.start > 1) {
                    this.query.start--;
                    this.getList();
                } else {
                    this.$message.info('到顶了');
                }
            },
            onNext() {
                if (this.query.start < this.total) {
                    this.query.start++;
                    this.getList();
                } else {
                    this.$message.info('到底了');
                }
            },
            toNextHandle() {
                if (this.query.start < this.total) {
                    this.getList();
                } else {
                    this.query.start = 1;
                    this.getList();
                }
            },
            // 取消异常
            setNormal(id_card, type) {
                if (this.loading) {
                    return;
                }
                this.loading = true;
                this.isDisKeyDown = true;
                this.$confirm('确认取消异常?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let obj = {
                        message: '',
                        id_card: id_card,
                        status: type
                    }
                    setYjAbnormal(obj)
                        .then(res => {
                            console.log(res);
                            if (res.data.code != 200) {
                                this.loading = false;
                                this.isDisKeyDown = false;
                                return this.$message.error(res.data.message);
                            }
                            this.$store.dispatch('GenerateRoutes').then(response => {
                            }).catch(error => {
                            })
                            this.loading = false;
                            this.isDisKeyDown = false;
                            this.toNextHandle();
                            this.$message.success(res.data.message);
                        })
                        .catch(error => {
                            this.loading = false;
                            this.isDisKeyDown = false;
                            console.log(error);
                        });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消操作'
                    });
                    this.loading = false;
                    this.isDisKeyDown = false;
                });
            },
            getList() {
                if (this.loading) {
                    return;
                }
                this.loading = true;
                getAbnormalList(this.query)
                    .then(res => {
                        if (res.data.code != 200) {
                            this.dataList = [];
                            this.total = 0;
                            this.loading = false;
                            return this.$message.error(res.data.message);
                        }
                        this.loading = false;
                        this.dataList = res.data.data.list;
                        this.total = res.data.data.count;
                    })
                    .catch(err => {
                        this.loading = false;
                        console.log(err)
                    })
            },
        },
        destroyed() {
            // 删除事件处理器
            document.onkeydown = null;
        },
    };
</script>

<style scoped lang="scss">
    @import "../../assets/css/giveMark";
</style>

